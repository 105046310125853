<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-group-message-96.png"
          class="h-20 ml-2 inline-block"
        />
        آمار پست ها
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mb-5">
        <!-- <div><h2 class="text-lg font bold">جستجوی پست ها</h2></div> -->

        <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
          <MnrSelect
            @change="submit"
            title="دسته بندی"
            placeholder="همه"
            v-model="inputs.branch.value"
            :options="inputs.branch.options"
          />
          <MnrSelect
            @change="submit"
            v-model="inputs.status.value"
            :options="inputs.status.options"
            title="وضعیت"
            placeholder="همه"
          />
          <MnrSelect
            @change="submit"
            :disabled="inputs.branch.value != 'queue'"
            v-model="inputs.queue"
            :options="channel.queues"
            oValue="id"
            oText="name"
            :dValue="0"
            dText="همه"
            title="صف"
            placeholder="همه"
          />
        </div>
      </div>
      <!-- ANCHOR - posts -->
      <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-2">
        <div
          class="p-3 bg-blueGray-100 border border-t-4 rounded-md"
          :class="{
            'border-green-400': getStatus(post) == 'sended',
            'border-red-400': getStatus(post) == 'draft',
            'border-amber-400': getStatus(post) == 'wait',
          }"
          v-for="post in posts"
          :key="post.id"
        >
          <div>
            <div class="flex p-2 even:bg-blueGray-200">
              <div class="w-full">نوع پست</div>
              <div class="w-full text-left">
                {{ type_list[post.file_type] ?? post.file_type }}
              </div>
            </div>
            <div class="flex p-2 even:bg-blueGray-200">
              <div class="w-full">وضعیت</div>
              <div class="w-full text-left" v-if="getStatus(post) == 'sended'">
                ارسال شده
              </div>
              <div class="w-full text-left" v-else-if="getStatus(post) == 'wait'">
                در انتظار
              </div>
              <div class="w-full text-left" v-else>پیشنویس</div>
            </div>
            <div class="flex p-2 even:bg-blueGray-200">
              <div class="w-full">کلیدهای لایک</div>
              <div class="w-full text-left">{{ post.with_key ? "روشن" : "خاموش" }}</div>
            </div>
            <div class="flex p-2 even:bg-blueGray-200">
              <div class="w-full">کلیدهای لینک‌دار</div>
              <div class="w-full text-left">
                {{ post.with_link_keys ? "روشن" : "خاموش" }}
              </div>
            </div>
            <div class="p-2 even:bg-blueGray-200">
              <div class="w-full mb-2">متن</div>
              <div class="w-full h-20 overflow-y-auto overflow-x-hidden scroll-style">
                {{ post.text }}
              </div>
            </div>
            <div class="flex gap-2">
              <Button
                :to="{
                  name: 'ChannelOwnerSettingPostInfo',
                  params: {
                    id: $route.params.id,
                    channelId: channel.id,
                    postId: post.id,
                  },
                }"
                class="h-10"
                color="teal"
              >
                جزئیات
              </Button>
              <Button
                :to="{
                  name: 'ChannelOwnerSettingPost',
                  params: {
                    id: $route.params.id,
                    channelId: channel.id,
                    postId: post.id,
                  },
                }"
                class="h-10"
                color="cyan"
              >
                ویرایش
              </Button>
              <Button
                :href="
                  channel.username &&
                  `https://t.me/${channel.username}/${post.message_id}`
                "
                :target="channel.username && '_blank'"
                :disabled="!channel.username"
                v-if="post.sended_at"
                class="h-10"
                color="sky"
              >
                در کانال
              </Button>
              <Button v-else class="h-10" color="red" @click="deletePost(post.id)">
                حذف
              </Button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import _ from "lodash";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrSelect,
  },
  data() {
    return {
      firstLoading: true,
      errors: [],
      channel: {},
      posts: [],
      // L_:_,
      // rateKey: [],
      type_list: {
        text: "متن",
        photo: "عکس",
        video: "فیلم",
      },
      inputs: {
        branch: {
          value: "all",
          options: [
            {
              text: "همه",
              value: "all",
            },
            {
              text: "در صف",
              value: "queue",
            },
            {
              text: "زمان بندی شده",
              value: "time",
            },
            {
              text: "ارسال مستقیم در کانال",
              value: "channel",
            },
          ],
        },
        status: {
          value: "all",
          options: [
            {
              text: "همه",
              value: "all",
            },
            {
              text: "ارسال شده",
              value: "sended",
            },
            {
              text: "در انتظار",
              value: "wait",
            },
            {
              text: "پیشنویس ها",
              value: "draft",
            },
          ],
        },
        queue: 0,
      },
    };
  },
  mounted() {
    // this.getData();

    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.inputs.branch.value =
          $this.$route.query.branch ?? $this.inputs.branch.value;
        $this.inputs.status.value =
          $this.$route.query.status ?? $this.inputs.status.value;
        $this.inputs.queue = Number($this.$route.query.queue) || $this.inputs.queue;

        $this.getData();
      })
      .catch();
  },
  methods: {
    getData() {
      let $this = this;
      $this.posts = [];
      $this.$axios
        .get(`api/owner-channels/${$this.$route.params.channelId}/post`, {
          params: {
            id: $this.$store.state.user.id,
            branch: $this.inputs.branch.value,
            status: $this.inputs.status.value,
            queue: $this.inputs.queue,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.posts = response.data.posts;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    getStatus(post) {
      if (post.sended_at) {
        return "sended";
      } else if (post.send_on || post.queue_id) {
        return "wait";
      } else {
        return "draft";
      }
    },
    submit() {
      console.log(this.inputs);
      this.$router.replace({
        query: {
          branch: this.inputs.branch.value,
          status: this.inputs.status.value,
          queue: this.inputs.queue,
        },
      });
      this.getData();
    },
    deletePost(id) {
      let $this = this;
      $this.$axios
        .delete(`api/owner-channels/${$this.$route.params.channelId}/post/${id}`, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.$notify({ text: "پست حذف شد", type: "success" });
          _.remove($this.posts, (x) => x.id == id);
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا", type: "error" });
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>
